import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from './components/NotFound';
import store from './store/store';
import GoogleAnalytics from '@/components/base-components/Analytics/GoogleAnalytics';
import {
  BUY,
  PACKAGES,
  PAGES_NAMES,
  PAGES_PATHS,
  SELECT_PACKAGE,
  POLICY,
  TERMS,
} from '@/pages';
import {GET_BASIC_PASS_SELECTED} from '@/store/getterTypes';
import VisaicV3Analytics from '@/components/base-components/Analytics/VisaicV3Analytics';
import {URL_QUERIES} from '@/const';

import appRoutes from '@/league/leagueRouters';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ?? {x: 0, y: 0};
  },
  routes: [
    {
      ...PAGES_PATHS.LOGIN,
      meta: {
        title: 'Login',
      },
      component: () => import(
          /* webpackChunkName: "auth-view" */ './views/LoginScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.REGISTRATION,
      meta: {
        title: 'Registration',
      },
      component: () => import(
          /* webpackChunkName: "auth-view" */ './views/RegistrationScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.VERIFY_NOTICE,
      meta: {
        title: 'Verify notice',
      },
      component: () => import(
          /* webpackChunkName: "auth-view" */ './views/VerifyNoticeScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.VERIFY_EMAIL,
      meta: {
        title: 'Verify',
      },
      component: () => import(
          /* webpackChunkName: "auth-view" */ './views/VerifyScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.PASSWORD_RESET_REQUEST,
      meta: {
        title: 'Password reset request',
      },
      component: () => import(
          // eslint-disable-next-line max-len
          /* webpackChunkName: "auth-view" */ './views/PasswordResetRequestScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.PASSWORD_RESET,
      meta: {
        title: 'Password reset',
      },
      component: () => import(
          /* webpackChunkName: "auth-view" */ './views/PasswordResetScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.PROFILE,
      meta: {
        title: 'Profile',
      },
      component: () => import(/* webpackChunkName: "profile-view" */ './views/ProfileScreen.vue'),
      children: [
        {
          ...PAGES_PATHS.ORDER_LIST,
          component: () => import(
              /* webpackChunkName: "profile-view" */ './components/profile/OrdersList.vue'),
          children: [
            {
              ...PAGES_PATHS.ACTIVE_ORDERS,
              component: () => import(
                  /* webpackChunkName: "profile-view" */ './components/profile/ActiveOrders.vue'),
            },
            {
              ...PAGES_PATHS.TRANSACTIONS,
              component: () => import(
                  /* webpackChunkName: "profile-view" */
                  './components/profile/HistoryOrdersList.vue'
              ),
            },
            {
              ...PAGES_PATHS.CLIPS,
              component: () => import(
                  /* webpackChunkName: "profile-view" */ './components/profile/UserClips.vue'),
            },
          ],
        },
        {
          ...PAGES_PATHS.PROFILE_EDIT,
          meta: {
            title: 'Update profile',
          },
          beforeEnter: (to, from, next) => {
            if (store.getters.isLogged) {
              next();
            } else {
              next(PAGES_PATHS.LOGIN);
            }
          },
          component: () => import(
              /* webpackChunkName: "schedule-view" */ './components/profile/UpdateProfileComponent'
          ),
        },
      ],
    },
    {
      ...PAGES_PATHS.CONTACTS,
      meta: {
        title: 'Contacts',
      },
      component: () => import(
          /* webpackChunkName: "contacts-view" */ './views/ContactScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.BUY,
      meta: {
        title: 'Buy',
      },
      component: () => import(
          /* webpackChunkName: "buy-view" */ './views/BuyScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.SELECT_PACKAGE,
      meta: {
        title: 'Select package',
      },
      component: () => import(
          /* webpackChunkName: "buy-view" */ './views/SelectPackageScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.FAQ,
      meta: {
        title: 'FAQ',
      },
      component: () => import(
          /* webpackChunkName: "faq-view" */ './views/FaqScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.SIMULATE,
      meta: {
        title: 'Simulate',
      },
      component: () => import(
          /* webpackChunkName: "simulate-view" */ './views/SimulateScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.ERROR,
      meta: {
        title: '404',
      },
      component: NotFound,
    },
    {
      ...PAGES_PATHS.ABOUT,
      meta: {
        title: 'About us',
      },
      component: () => import(
          /* webpackChunkName: "faq-view" */ './views/AboutUsScreen.vue'
      ),
    },
    ...appRoutes,
  ],
});

const NOT_REMEMBERED_PAGES = [
  PAGES_NAMES.LOGIN,
  PAGES_NAMES.REGISTRATION,
  PAGES_NAMES.VERIFY_NOTICE,
  PAGES_NAMES.PASSWORD_RESET_REQUEST,
  PAGES_NAMES.PASSWORD_RESET,
  PAGES_NAMES.ERROR,
  PAGES_NAMES.VERIFY_EMAIL,
];

export const DISABLE_FOR_LOGGED = [
  PAGES_PATHS.LOGIN.path,
  PAGES_PATHS.REGISTRATION.path,
  PAGES_PATHS.PASSWORD_RESET_REQUEST.path,
  PAGES_PATHS.PASSWORD_RESET.path,
];
const JUST_FOR_LOGGED = [
  PAGES_PATHS.BUY.path,
];
const AUTHORIZED_PATHS = [
  PAGES_PATHS.LOGIN.path,
  PAGES_PATHS.REGISTRATION.path,
];
export const PATH_TO_REDIRECT_IF_LOGGED = '/';
export const NO_BASIC_PASS_PATHS = [
  POLICY.path,
  TERMS.path,
  PACKAGES.path,
  BUY.path,
  SELECT_PACKAGE.path,
];
/**
 * @param store: Object
 * @param store.state: Object
 * @param store.state.auth.user.temporary_password: Boolean
 */
router.beforeEach((to, from, next) => {
  Vue.$storage.set('previous_page_for_analytics', `${location.origin}${from.fullPath}`);
  const logged = store.getters.isLogged;
  const basicPassSelected = store.getters[GET_BASIC_PASS_SELECTED];

  // to avoid cycling if not logged user tried to buy package
  // but go back from login / registration pages by history navigation without logged in.
  if (!logged && JUST_FOR_LOGGED.includes(to.path) && AUTHORIZED_PATHS.includes(from.path)) {
    return router.go(-1);
  }

  if (logged && !basicPassSelected) {
    if (!NO_BASIC_PASS_PATHS.includes(to.path)) {
      return next(PACKAGES);
    }
  }

  const hasTempPass = store.state?.auth?.user?.temporary_password;
  if (logged && hasTempPass && (PAGES_PATHS.PROFILE_EDIT.path !== to.path)) {
    if (PAGES_PATHS.PROFILE_EDIT.path === from.path) {
      return false;
    }
    next(PAGES_PATHS.PROFILE_EDIT);
  } else if (logged && DISABLE_FOR_LOGGED.includes(to.path)) {
    next(PATH_TO_REDIRECT_IF_LOGGED);
  } else if (NOT_REMEMBERED_PAGES.includes(to.name)) {
    next();
  } else {
    Vue.$storage.set('form_fullPath', from.name ?
      `${location.origin}${from.fullPath}` :
      document.referrer,
    );
    Vue.$storage.set('last_page', to.path);
    Vue.$storage.set('last_page_query', to.query);
    Vue.$storage.set('last_page_params', to.params);
    next();
  }
});
router.afterEach((to, from) => {
  GoogleAnalytics.pageView({
    page_title: to.meta?.title,
    page_location: `${location.origin}${to.path}`,
    page_path: to.path,
  });

  if (to.query[URL_QUERIES.V3_OUTER_TRACKING]) {
    VisaicV3Analytics.outerTracker = to.query[URL_QUERIES.V3_OUTER_TRACKING];
  }
  VisaicV3Analytics.event({
    event: 'visit_page',
    title: to?.meta.title,
    url: `${location.origin}${to.path}`,
    referrer: `${location.origin}/${from.fullPath}`,
    api_key: Vue.$storage.get('api_key'),
  });
});

export default router;
