export const GET_CUSTOM_FORM_DATA_FOR_ALIAS = 'GET_CUSTOM_FORM_DATA_FOR_ALIAS';
export const GET_FAVORITES_IDS_RECURSIVELY = 'GET_FAVORITES_IDS_RECURSIVELY';
export const GET_PAGE_ID_FOR_URL = 'GET_PAGE_ID_FOR_URL';
export const GET_BASIC_PASS_SELECTED = 'GET_BASIC_PASS_SELECTED';
export const GET_BASIC_PASS_NEED = 'GET_BASIC_PASS_NEED';
export const GET_CHANNEL_SLIDES_FOR_QUERY = 'GET_CHANNEL_SLIDES_FOR_QUERY';
export const GET_PASSES_WITH_ACCESSES = 'GET_PASSES_WITH_ACCESSES';
export const GET_MERGED_PASSES_BY_NAME = 'GET_MERGED_PASSES_BY_NAME';
export const PREPARE_SELECTED_PASSES = 'PREPARE_SELECTED_PASSES';
export const TRIAL_ACCESS_AVAILABLE = 'TRIAL_ACCESS_AVAILABLE';
export const ALL_CLIPS = 'ALL_CLIPS';
