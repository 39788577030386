import request from './request';
import {
  APPLY_COUPON_API,
  ORDERS_API,
  PACKAGES_API,
  STRIPE_BUY_API,
  STRIPE_CARDS_API, STRIPE_INTENT_API,
  STRIPE_PORTAL_API, STRIPE_TRIAL_SUBSCRIPTION_API,
  STRIPE_UNSUBSCRIBE_API,
  SUBSCRIPTIONS_API,
} from '@/urls';
import {API_METHODS} from '@/const';

export default {
  buy: function(params = {}) {
    return request({
      url: STRIPE_BUY_API,
      method: API_METHODS.POST,
      params,
    });
  },
  packages: function(params = {}) {
    return request({
      url: PACKAGES_API,
      method: API_METHODS.GET,
      params,
    });
  },
  subscriptions: function(params = {}) {
    return request({
      url: SUBSCRIPTIONS_API,
      method: API_METHODS.GET,
      params,
    });
  },
  package: function(id) {
    return request({
      url: `${PACKAGES_API}/${id}`,
      method: API_METHODS.GET,
    });
  },
  subscription: function(id) {
    return request({
      url: `${SUBSCRIPTIONS_API}/${id}`,
      method: API_METHODS.GET,
    });
  },

  orders: function(params = {}) {
    if (!params?.hasOwnProperty('limit')) {
      params.no_limit = 1;
    }
    return request({
      url: ORDERS_API,
      method: API_METHODS.GET,
      params,
    });
  },
  orderById: function({order_id: id} = {}) {
    return request({
      url: `${ORDERS_API}/${id}`,
      method: API_METHODS.GET,
    });
  },
  /**
   * @param {Object}  params {promo: String}
   * @return {Promise<*>}
   */
  applyCoupon: function(params) {
    return request({
      url: APPLY_COUPON_API,
      method: API_METHODS.GET,
      params,
    });
  },
  /**
   * @param {Object} params {order_subscription_id: Number}
   * @return {Promise<*>}
   */
  unsubscribe: function(params) {
    return request({
      url: STRIPE_UNSUBSCRIBE_API,
      method: API_METHODS.POST,
      params,
    });
  },
  /**
   * @return {Promise<*>}
   */
  getStripePortal: function() {
    return request({
      url: STRIPE_PORTAL_API,
      method: API_METHODS.GET,
    });
  },
  getUserSavedCards: function() {
    return request({
      url: STRIPE_CARDS_API,
      method: API_METHODS.GET,
    });
  },
  startTrial: function(params) {
    return request({
      url: STRIPE_TRIAL_SUBSCRIPTION_API,
      method: API_METHODS.POST,
      params,
    });
  },
  getClientSecretStripe: function(params) {
    return request({
      url: STRIPE_INTENT_API,
      method: API_METHODS.POST,
      params,
    });
  },
};
